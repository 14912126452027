import React from 'react';
import Layout from 'components/Layout';
import { SliceZone } from 'components/Shared';
import { graphql } from 'gatsby';
import { getRelevantNode } from 'src/utils/helpers';

const SubPage = ({ data, pageContext, location }) => {
  const {
    data: { body, meta_title, meta_description, meta_image },
    lang: pageLang,
    alternate_languages: altLangs,
  } = getRelevantNode(data.subpage, pageContext.lang);

  return (
    <Layout
      location={location}
      pageLang={pageLang}
      altLangs={altLangs}
      seo={{
        title: meta_title,
        description: meta_description,
        meta_image: meta_image?.url,
      }}
    >
      <SliceZone allSlices={body} language={pageLang} />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query SubpageQuery($id: String!) {
    subpage: allPrismicSubpage(filter: { id: { eq: $id } }) {
      nodes {
        lang
        tags
        alternate_languages {
          lang
          uid
        }
        data {
          meta_title
          meta_description
          meta_image {
            url
          }
          body {
            ... on PrismicSubpageBodyTeam {
              id
              slice_type
              primary {
                headline {
                  text
                }
              }
              items {
                name {
                  text
                }
                description1 {
                  text
                }
                linkedin_profile {
                  url
                }
                profile_picture {
                  url
                }
                title1 {
                  text
                }
              }
            }
            ... on PrismicSubpageBodyTwoColumns {
              id
              slice_type
              items {
                headline {
                  text
                }
                text {
                  html
                }
                image {
                  alt
                  url
                }
              }
            }
            ... on PrismicSubpageBodyTextImage {
              id
              slice_type
              primary {
                headline {
                  text
                }
                text {
                  text
                }
              }
            }
            ... on PrismicSubpageBodyLeadSignup {
              id
              slice_type
            }
            ... on PrismicSubpageBodyTextImage2 {
              id
              slice_type
              primary {
                headline {
                  text
                }
                image {
                  alt
                  url
                }
                text {
                  text
                }
              }
            }
            ... on PrismicSubpageBodyNamedSlice {
              slice_type
              id
              items {
                image {
                  url
                  alt
                }
                image_title {
                  text
                }
              }
              primary {
                button_text {
                  text
                }
                button_text_demo {
                  text
                }
                open_hubspot_dialog
                button_text_secondary {
                  text
                }
                headline_highlight {
                  text
                }
                headline_post_highlight {
                  text
                }
                headline_pre_highlight {
                  text
                }
                highlight_newline
                image_configuration
                has_iced_background
                has_small_padding
                has_narrow_text_column
                invert_order
                has_no_padding_bottom
                navigation_link {
                  url
                }
                navigation_link_is_download
                navigation_link_secondary {
                  url
                }
                slice_title {
                  text
                }
                text {
                  text
                }
                section_title {
                  text
                }
              }
            }
            ... on PrismicSubpageBodyIntegrationsSlice {
              slice_type
              id
              items {
                image {
                  url
                  alt
                }
                text {
                  text
                }
                button_text {
                  text
                }
                navigation_link {
                  url
                }
              }
              primary {
                headline_highlight {
                  text
                }
                headline_post_highlight {
                  text
                }
                headline_pre_highlight {
                  text
                }
                slice_title {
                  text
                }
              }
            }
            ... on PrismicSubpageBodyWhySeedtrace {
              id
              slice_type
              items {
                fact {
                  document {
                    ... on PrismicNumericFact {
                      id
                      data {
                        description {
                          html
                        }
                        headline {
                          text
                        }
                        number
                        unit {
                          text
                        }
                      }
                    }
                  }
                }
              }
              primary {
                headline_post_highlight {
                  text
                }
                headline_highlight {
                  text
                }
                headline_pre_highlight {
                  text
                }
                slice_title {
                  text
                }
                text {
                  text
                }
                center_text
                button_text {
                  text
                }
                button_link {
                  url
                }
              }
            }
            ... on PrismicSubpageBodyDarkDivider {
              id
              primary {
                headline_post_highlight {
                  text
                }
                headline_highlight {
                  text
                }
                headline_pre_highlight {
                  text
                }
                text {
                  text
                  html
                }
                button_text {
                  text
                }
                button_text_demo {
                  text
                }
                button_text_secondary {
                  text
                }
                navigation_link {
                  url
                }
                navigation_link_is_download
                navigation_link_secondary {
                  url
                }
                image_avatar {
                  url
                  alt
                }
                avatar_name {
                  text
                }
                avatar_title {
                  text
                }
              }
              slice_type
            }
            ... on PrismicSubpageBodyCaseStudyTeaser {
              id
              slice_type
              primary {
                headline_pre_highlight {
                  text
                }
                headline_highlight {
                  text
                }
                headline_post_highlight {
                  text
                }
                text {
                  text
                  html
                }
                case_study_image {
                  url
                  alt
                }
                testimonial_quote {
                  document {
                    ... on PrismicTestimonalQuote {
                      id
                      data {
                        person_image {
                          url
                          alt
                        }
                        quote {
                          text
                        }
                        person_name {
                          text
                        }
                        company_position {
                          text
                        }
                      }
                    }
                  }
                }
                button_text {
                  text
                }
                navigation_link {
                  url
                }
              }
            }
            ... on PrismicSubpageBodyAskUsAnything {
              id
              slice_type
              primary {
                contact_form {
                  document {
                    ... on PrismicContactForm {
                      id
                      data {
                        button_text {
                          text
                        }
                        company_placeholder {
                          text
                        }
                        email_placeholder {
                          text
                        }
                        headline_highlight {
                          text
                        }
                        headline_post_highlight {
                          text
                        }
                        headline_pre_highlight {
                          text
                        }
                        message_placeholder {
                          text
                        }
                        name_placeholder {
                          text
                        }
                        text {
                          text
                        }
                        success_message {
                          text
                        }
                        error_message {
                          text
                        }
                        privacy_notice {
                          text
                          raw
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicSubpageBodyThreeColumns {
              id
              slice_type
              primary {
                slice_title {
                  text
                }
                headline_highlight {
                  text
                }
                headline_post_highlight {
                  text
                }
                headline_pre_highlight {
                  text
                }
                show_rows
                has_iced_background
              }
              items {
                button_text {
                  text
                }
                navigation_link {
                  url
                }
                icon {
                  url
                  alt
                }
                text {
                  text
                }
                title1 {
                  text
                }
              }
            }
            ... on PrismicSubpageBodyStandaloneItems {
              slice_type
              items {
                item {
                  document {
                    ... on PrismicTestimonalQuote {
                      id
                      type
                      data {
                        company_position {
                          text
                        }
                        person_image {
                          url
                          alt
                        }
                        person_name {
                          text
                        }
                        quote {
                          text
                        }
                      }
                    }
                    ... on PrismicNumericFact {
                      id
                      type
                      data {
                        description {
                          text
                        }
                        headline {
                          text
                        }
                        number
                        unit {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicSubpageBodyNamedSliceDivided {
              id
              slice_type
              primary {
                slice_title {
                  html
                  text
                  raw
                }
                button_text {
                  text
                }
                button_text_secondary {
                  text
                }
                headline_highlight {
                  text
                }
                headline_post_highlight {
                  text
                }
                headline_pre_highlight {
                  text
                }
                highlight_newline
                invert_order
                navigation_link {
                  url
                }
                navigation_link_secondary {
                  url
                }
                text {
                  text
                  html
                }
              }
              items {
                item_headline {
                  text
                }
                item_text {
                  text
                }
              }
            }
            ... on PrismicSubpageBodyTextColumns {
              id
              slice_type
              primary {
                headline_highlight {
                  text
                }
                headline_post_highlight {
                  text
                }
                headline_pre_highlight {
                  text
                }
                slice_title {
                  text
                }
                has_iced_background
                has_small_padding
              }
              items {
                text {
                  text
                }
                title1 {
                  text
                }
              }
            }

            ... on PrismicSubpageBodySeedtraceTeam {
              id
              slice_type
              primary {
                slice_title {
                  text
                }
                headline_highlight {
                  text
                }
                headline_post_highlight {
                  text
                }
                headline_pre_highlight {
                  text
                }
              }
              items {
                description {
                  text
                }
                image {
                  alt
                  url
                }
                linkedin {
                  url
                }
                person {
                  text
                }
                position {
                  text
                }
              }
            }

            ... on PrismicSubpageBodyJobSlice {
              id
              slice_type
              primary {
                slice_title {
                  text
                }
                button_text {
                  text
                }
                headline_highlight {
                  text
                }
                headline_post_highlight {
                  text
                }
                headline_pre_highlight {
                  text
                }
                navigation_link {
                  url
                }
                text {
                  html
                }
              }
              items {
                text {
                  text
                }
                title1 {
                  text
                }
                navigation_link {
                  url
                }
              }
            }

            ... on PrismicSubpageBodyAccordionSlice {
              id
              slice_type
              primary {
                slice_title {
                  text
                }
                headline_highlight {
                  text
                }
                headline_post_highlight {
                  text
                }
                headline_pre_highlight {
                  text
                }
                text {
                  text
                }
              }
              items {
                image {
                  url
                  alt
                }
                step_title {
                  text
                }
                text {
                  text
                }
              }
            }

            ... on PrismicSubpageBodyBenefitsSlice {
              id
              slice_type
              primary {
                section_title {
                  text
                }
                headline_highlight {
                  text
                }
                headline_post_highlight {
                  text
                }
                headline_pre_highlight {
                  text
                }
                text {
                  text
                }
              }
              items {
                image {
                  url
                  alt
                }
                text {
                  text
                }
                title1 {
                  text
                }
              }
            }

            ... on PrismicSubpageBodySocialMediaHighlight {
              id
              slice_type
              primary {
                title1 {
                  text
                }
              }
              items {
                social_network
                social_page {
                  link_type
                  url
                }
              }
            }
            ... on PrismicSubpageBodyBrands {
              id
              slice_type
              items {
                title {
                  text
                }
                location {
                  text
                }
                image {
                  url
                  alt
                }
                link {
                  url
                  link_type
                  target
                }
              }
              primary {
                title {
                  text
                }
              }
            }
            ... on PrismicSubpageBodyNews {
              id
              slice_type
              primary {
                slice_title {
                  text
                }
                include_podcasts
                include_news_articles
                include_press_releases
              }
            }
            ... on PrismicSubpageBodyVerifiedPaymentsWidget {
              id
              slice_type
              primary {
                slug {
                  text
                }
                headline_post_highlight {
                  text
                }
                headline_highlight {
                  text
                }
                headline_pre_highlight {
                  text
                }
                description {
                  html
                }
              }
            }
            ... on PrismicSubpageBodyProductSliderWidget {
              id
              slice_type
              primary {
                api_key {
                  text
                }
                headline_post_highlight {
                  text
                }
                headline_highlight {
                  text
                }
                headline_pre_highlight {
                  text
                }
                text {
                  text
                  html
                }
                white_background
              }
            }
            ... on PrismicSubpageBodyHubspotForm {
              id
              slice_type
              primary {
                region {
                  text
                }
                portal_id {
                  text
                }
                form_id {
                  text
                }
                version {
                  text
                }
                headline {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`;
